import React, { FC } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import BasicTemplate from "@templates/BasicTemplate";
import Html, { convertNodeToElement, Transform } from "react-html-parser";

const StyledWrapper = styled.div`
  padding-top: 160px;
  width: 90%;
  max-width: 1700px;
  margin: auto;
  line-height: 1.6;

  h1 {
    font-size: 4.8rem;
    font-weight: 700;
  }

  h2 {
    font-weight: 500;
    font-size: 3.6rem;
  }

  h3 {
    font-size: 2.8rem;
    font-weight: 500;
  }

  a {
    color: ${({ theme }) => theme.primary};
  }

  p {
    text-align: justify;
  }

  @media (max-width: 720px) {
    padding-top: 140px;

    h1 {
      font-size: 2.8rem;
    }

    h2 {
      font-size: 2.4rem;
    }

    h3 {
      font-size: 1.8rem;
    }
  }
`;

const transform: Transform = (node, index) => {
  if (node.name === "h2") {
    let number = !isNaN(parseInt(node.children[0]?.data?.[1]))
      ? node.children[0]?.data?.slice(0, 2)
      : node.children[0]?.data?.[0];

    return (
      <h2 id={`point-${number}`} key={index}>
        {node.children.map((node: any, index: any) => (
          <React.Fragment key={index}>
            {convertNodeToElement(node, index, transform)}
          </React.Fragment>
        ))}
      </h2>
    );
  }
};

const PrivacyPolicy: FC<Props> = ({ data }) => {
  return (
    <BasicTemplate title="Polityka prywatności | Pałacowa Park" isSubPage>
      <StyledWrapper>
        {Html(data.directus.Privacy_Policy.content, {
          transform: transform,
        })}
      </StyledWrapper>
    </BasicTemplate>
  );
};

interface Props {
  data: {
    directus: {
      Privacy_Policy: {
        content: string;
      };
    };
  };
}

export const query = graphql`
  {
    directus {
      Privacy_Policy {
        content
      }
    }
  }
`;

export default PrivacyPolicy;
